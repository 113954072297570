<template>
  <div class="search_bar">
    <div class="main_search_bar">
      <label for="address_search">{{ language.find_stores_near }}</label>
      <input
        type="text"
        :disabled="loading"
        name="address_search"
        :placeholder="language.search_box"
        v-model="searchParams.address"
        @focus="onSearchBoxFocus"
        :class="{ 'using-my-location':(searchParams.address == 'My Location'), }"
        class="address-search-box"
        :style="'background-image:url(\'' + myLocationImage.replace('-white', '') + '\');'"  
        id="address_search"
      />

      <button
        class="use_my_location"
        title="Use My Location"
        v-if="supportsGeolocation"
        @click="useMyLocation"
      >
        <img :src="myLocationImage" alt="Use My Location" />
      </button>

      <button id="search_submit" :disabled="loading" @click="search" title="Search map">
        <span>{{ language.search }}</span>
      </button>

      <!--
                <a id="btn-use-location" href="#"><i class="fa fa-map-marker" aria-hidden="true"></i> Use My Location</a>
      -->
    </div>

    <div class="search_within_distance">
      <label for="within_distance">{{ language.distance }}</label>

      <select id="within_distance" v-model="searchParams.distance">
        <option
          v-for="distance in distances"
          :key="distance"
          :value="distance"
        >{{ getDistanceName(distance) }}</option>
      </select>
    </div>

    <div id="map_search_filters" v-if="filters.length > 0">
      <span v-for="filter in filters" :key="filter.filterid">
        <label>
          <input
            type="checkbox"
            :disabled="loading"
            :value="filter.filterid"
            v-model="searchParams.filters"
          />
          {{ filter.name }}
        </label>
      </span>
    </div>

    <div class="break"></div>

    <div class="map-clear-wrapper">
      <a id="btn-clear-search" href="#" @click="clear" title="Clear search parameters and reset map">Clear</a>
    </div>
    
  </div>
</template>

<script>
import * as apiCalls from "../utils/apiCalls";
import * as L from "leaflet";
import eventBus from "../utils/eventBus";
import Globals from "../utils/globals";

export default {
  name: "SearchBar",
  data: function() {
    return {
      language: {
        distance: "Distance",
        find_stores_near: "Find Stores Near",
        search_box: "Search..",
        search: "Search"
      },
      filters: [],
      searchParams: {
        address: "",
        filters: [],
        distance: 0
      },
      loading: false,
      distances: [],
      distanceWord: "miles",
      supportsGeolocation: false,
      myLocationImage: process.env.VUE_APP_URL + "/images/my-location-white.png",
      defaultDistance: 25
    };
  },
  methods: {
    onSearchBoxFocus() {
      if(this.searchParams.address == "My Location") {
        this.searchParams.address = "";
      }
    },
    useMyLocation() {
      this.searchParams.address = "My Location";

      this.search();
    },
    search() {
      var bounds = this.map.getBounds();

      var settings = Globals.settings;

      eventBus.$emit("loading", true);

      if (this.searchParams.address == "My Location") {
        navigator.geolocation.getCurrentPosition((position) => {
            var geocodeCenter = L.latLng(
              position.coords.latitude,
              position.coords.longitude
            );

            this.map.flyTo(geocodeCenter, 8);

            apiCalls
              .searchLocations({
                shop: this.$Shopify.shop,
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                limit: Globals.searchLimit,
                distance: this.searchParams.distance,
                filters: this.searchParams.filters
              })
              .then(result => {
                eventBus.$emit("loading", false);

                this.notifyLocationsChanged(result.data);
              });

            //find_new_stores(1, position.coords.latitude, position.coords.longitude);
          },
          function(e) {
            if (e.code == 1) {
              alert("Please allow location services.");

              eventBus.$emit("loading", false);
            } else {
              alert(this.language.error_finding_address);

              eventBus.$emit("loading", false);
            }
          },
          {
            timeout: 8000
          }
        );
      } else if(!this.searchParams.address) {
        alert(this.language.error_finding_address);

        eventBus.$emit("loading", false);
      } else {
        apiCalls
          .getGeocode(
            this.searchParams.address,
            settings.countryCodes,
            settings.shopId,
            settings.geocoderType,
            bounds,
            settings.onlySearchZip
          )
          .then(result => {
            if (result.status == 200) {
              if (result.data.HasResults) {
                var geocodeCenter = L.latLng(result.data.Lat, result.data.Lng);
             
                this.notifyLocationsChanged([]);

                this.map.flyTo(geocodeCenter, 8);

                apiCalls
                  .searchLocations({
                    shop: this.$Shopify.shop,
                    lat: result.data.Lat,
                    lng: result.data.Lng,
                    limit: Globals.searchLimit,
                    distance: this.searchParams.distance,
                    filters: this.searchParams.filters
                  })
                  .then(result => {
                   
                    eventBus.$emit("loading", false);

                    this.notifyLocationsChanged(result.data);
                  });
              } else {
                eventBus.$emit("loading", false);
                alert("Could not locate address.");
              }
            } else {
              eventBus.$emit("loading", false);
              alert("Error");
            }
          });
      }
    },
    clear() {
      this.searchParams.address = "";
      this.searchParams.filters.length = 0;
      this.searchParams.distance = this.defaultDistance;

      eventBus.$emit("resetLocations");
    },
    notifyLocationsChanged(locations) {
      eventBus.$emit("onLocationsChanged", locations, false);
    },
    getDistanceName(distance) {
      if (distance != -1) {
        return `${distance} ${this.distanceWord}`;
      } else {
        return "No Limit";
      }
    }
  },
  created() {
    apiCalls.getFilters(this.$Shopify.shop).then(result => {
      this.filters = result.data;
    });

    eventBus.$on("mapLoaded", map => {
      this.map = map;
    });

    eventBus.$on("loading", loading => {
      this.loading = loading;
    });

    this.distances = Globals.distances;

    if (Globals.distances.indexOf(Globals.defaultDistance) != -1) {
      this.searchParams.distance = Globals.defaultDistance;
      this.defaultDistance = Globals.defaultDistance;
    } else {
      this.searchParams.distance = Globals.distances[0];
      this.defaultDistance = Globals.distances[0];
    }

    if ("geolocation" in navigator) {
      this.supportsGeolocation = true;
    }

    if (Globals.settings.useMetric) this.distanceWord = "kilometers";

    this.language = Globals.settings.language;

  },
  mounted() {
    if (Globals.map != null) {
      this.map = Globals.map;
    }
  }
};
</script>


<style>


</style>