//HTTP api calls
import * as $http from "axios";

export function getFilters(shop) {
	return $http.get(`${process.env.VUE_APP_URL}/api/filters?shop=${shop}`);
}

export function getFiltersByLocation(shop, locationID) {
	return $http.get(`${process.env.VUE_APP_URL}/api/filters?shop=${shop}&locationID=${ locationID }`);
}

export function getStartingLocations(shopId) {
	return $http.get(`${process.env.VUE_APP_URL}/api/locationsearch?shopId=${ shopId }`);
}

export function searchLocations(params) {
	return $http.post(`${process.env.VUE_APP_URL}/api/locationsearch`, params);
}

export function getSettings(shop) {
	return $http.get(`${process.env.VUE_APP_URL}/api/vuewidgetsettings?shop=${ shop }`)
}

export function getGeocode(
	address,
	countryCodes,
	shopId,
	geocoder,
	leafBounds,
	onlySearchZip
) {
	let formattedBounds =
		Math.max(leafBounds.getSouthWest().lat, -90) +
		"," +
		Math.max(leafBounds.getSouthWest().lng, -180) +
		"," +
		Math.min(leafBounds.getNorthEast().lat, 90) +
		"," +
		Math.min(leafBounds.getNorthEast().lng, 180);

	let url = `${process.env.VUE_APP_URL}/api/geocode?q=${address}&countrycodes=${countryCodes}&bounds=${formattedBounds}&geocoderType=${geocoder}&shopid=${shopId}&onlySearchZip=${ onlySearchZip ? "1" : "0"}`;

	return $http.get(url);
}
