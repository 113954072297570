<template>
  <div class="map-container">
    <div class="map-frame">
      <div id="map" :style="cssProps"></div>
    </div>

    <div id="outside-popup-wrapper"></div>
  </div>

</template>

<script>
/**
 * This file handles rendering the leaflet map, and rendering markers * 
 */
import * as L from "leaflet";
import Vue from "vue";

import canvasMarkerLayer from "../utils/canvasMarkerLayer";
import mapPopup from "../utils/mapPopup";
import * as mapHelpers from "../utils/mapHelpers";
import eventBus from "../utils/eventBus";
import Globals from "../utils/globals";
import * as apiCalls from "../utils/apiCalls";

import MarkerPopup from "./MarkerPopup";
//import LoadingBar from "./LoadingBar";

export default {
  name: "LeafletMap",
  props: {},
  data: function() {
    return {
      locations: []
    };
  },
  methods: {
    showMarkers(markers) {

      
      this.mLayer.clearSelectedMarkers();

      //this is used to highlight the selected marker popups
      markers.forEach((marker) => { marker.selected = true });

      this.mLayer._reset();

      //they should all be near each other

      //dont center popup on mobile because this would overlap the marker with the popup
      if(window.outerWidth > 1024) {
        this.map.panTo(markers[0].getLatLng(), { duration: 0.75 });
      }
      

      var locations = markers.map((marker) => { return marker.locationData });

      //here we dynamically load a Vue component
      var ComponentClass = Vue.extend(MarkerPopup);
      var instance = new ComponentClass({
        propsData: { locations: locations }
      });

      instance.$mount(); // pass nothing

      //maybe in the future
      //this was to allow the possibility of the popup not even being inside the leaflet map
      //which would help with the issue on mobile of the marker popup blocking.
      //maybe code something like a full screen popup?
      Globals.settings.selectionPopupOutside = false;

      if(Globals.settings.selectionPopupOutside) {
        document.querySelector("#outside-popup-wrapper").innerHTML = "";
        document.querySelector("#outside-popup-wrapper").appendChild(instance.$el);
      } else {
        document.querySelector("#custom-popup").innerHTML = "";
        document.querySelector("#custom-popup").appendChild(instance.$el);
        this.popup.show();
      }


      
    },
    locationsUpdated(firstLoad) {
      this.popup.hide();

      //redo everything on the map
      this.mLayer.clearLayers();

      var bounds = L.latLngBounds();
      this.locations.forEach(loc => {
        //console.log(loc.name + " : " + loc.lat + "==" + loc.long);
        var customIcon = L.icon({
          iconUrl: mapHelpers.getIconUrl(loc),
          iconSize: [32, 32], // size of the icon
          iconAnchor: [17.5, 35], // point of the icon which will correspond to marker's location
          popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
        });

        let marker = L.marker([loc.lat, loc.long], {
          icon: customIcon,
          draggable: false
        });

        marker.locationData = loc;
        loc.marker = marker;

  
        marker.on("click", e => {
          //console.log(e.target.locationData);
          eventBus.$emit("showMarkers", [e.target]);

          //this.showMarker(e.target);
        });

        bounds.extend(marker.getLatLng());

        this.mLayer.addMarker(marker);
      });

      var settings = Globals.settings;

      //handle first load
      if (settings.useCustomStartArea && firstLoad) {
        this.map.flyTo(
          [settings.startingLat, settings.startingLng],
          settings.startingZoom,
          {
            duration: 0.75,
            animate: true
          }
        );
      } else {
        if (this.locations.length > 0) {
          if (mapHelpers.isBoundsValid(bounds)) {
            this.map.flyToBounds(bounds, {
              duration: 0.75,
              animate: true
            });
          }
        }
      }
    },
    notifyLocationsChanged(locations, firstLoad) {
      

      eventBus.$emit("onLocationsChanged", locations, firstLoad);
    },
    mapLoaded(event) {
      var map = event.target;

      Globals.map = map;

      eventBus.$emit("mapLoaded", map);

      //load initial locations
      var settings = Globals.settings;
      
      eventBus.$emit("loading", true);

      apiCalls.getStartingLocations(settings.shopId).then(result => {
        this.notifyLocationsChanged(result.data, true);

        eventBus.$emit("loading", false);
      });
    }
  },
  components: {
    //LoadingBar
  },
  created() {
    eventBus.$on("showMarkers", markers => {
      this.showMarkers(markers);
    });

    eventBus.$on("onLocationsChanged", (locations, firstLoad) => {
      this.locations = locations;

      this.locationsUpdated(firstLoad);
    });

    eventBus.$on("resetLocations", () => {
      var settings = Globals.settings;

      eventBus.$emit("loading", true);
      
      apiCalls.getStartingLocations(settings.shopId).then(result => {
        this.notifyLocationsChanged(result.data, true);

        eventBus.$emit("loading", false);
      });
    })

    this.design = Globals.settings.design;

  },
  mounted() {
    this.map = L.map("map", {
      worldCopyJump: true,
      minZoom: 1
    })
      .on("load", this.mapLoaded)
      .setView([11, -19], 3);

    //should rotate this out
    let mapKey = "xMX2uqd4vpSKLF4BENk5";

    var settings = Globals.settings;

    mapHelpers.leafletMapSetup(this.map, mapKey, settings.map_style, settings.custom_mapbox_style, settings.mapbox_access_token);

    this.mLayer = new canvasMarkerLayer({});
    this.mLayer.addTo(this.map);

    this.popup = new mapPopup({});
    this.popup.addTo(this.map);

    //reused by all popups
    //this.popup = L.popup();
  },
  computed: {
    cssProps() {
      return {
        '--map-height': this.design.map_height,
      }
    }
  }
};
</script>

<style scoped>


#map {
  height: var(--map-height);
}


</style>