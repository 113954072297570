import * as L from 'leaflet';
//import 'mapbox-gl';
import 'mapbox-gl-leaflet';

import * as webGlTest from '../utils/webGlTest';

export function leafletMapSetup(map, mapKey, mapStyle, mapboxCustomStyle, mapboxAccessToken) {
    //fix to center marker and popup
    map.on('popupopen', function(e) {
        var px = map.project(e.popup._latlng); // find the pixel location on the map where the popup anchor is
        px.y -= e.popup._container.clientHeight / 2 // find the height of the popup container, divide by 2, subtract from the Y axis of marker location
        map.panTo(map.unproject(px), { animate: true }); // pan to new center
    });

    //var mapboxCustomStyle = "mapbox://styles/bgilbert5/ckeu3y90z01ov19qt6ajkmx0z";
    //var accessToken = 'pk.eyJ1IjoiYmdpbGJlcnQ1IiwiYSI6ImNrZXUzc3NpdTF0MjIycnF2Ymh3ZnBlOTMifQ.WBPhQf7FB-qBgumoLmzPrw';

    //vector
    if (webGlTest.supportsWebGl()) {
        
        if(mapStyle == "custom") {
            L.mapboxGL({
                attribution: "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a>, © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>, <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>",
                style: mapboxCustomStyle,
                accessToken: mapboxAccessToken
            }).addTo(map);
        } else {
            L.mapboxGL({
                attribution: '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
                style: `https://api.maptiler.com/maps/${ mapStyle }/style.json?key=${ mapKey }`,
                crossOrigin: true
            }).addTo(map);
        }


    } else { //raster


        if(mapStyle == "custom") {
            mapboxCustomStyle = mapboxCustomStyle.replace("mapbox://styles/", "");
            var mapboxApiUrl = `https://api.mapbox.com/styles/v1/${ mapboxCustomStyle }/tiles/{z}/{x}/{y}?access_token=${ mapboxAccessToken }`

            L.tileLayer(mapboxApiUrl, {
                attribution: "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a>, © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>, <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>",
            }).addTo(map);

        } else {
            L.tileLayer(`https://api.maptiler.com/maps/${ mapStyle }/{z}/{x}/{y}.png?key=${ mapKey }`, {
                attribution: '<a href="https://www.maptiler.com/license/maps/" target="_blank">© MapTiler</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
                crossOrigin: true
            }).addTo(map);
        }


    }

}

export function isBoundsValid(bounds) {
    
    if (typeof (bounds.getNorthWest()) == 'undefined') {
        return false;
    }

    if (bounds.getNorthWest().equals(bounds.getSouthEast())) {
        return false;
    }

    return true;
}

export function getIconUrl(location) {
    var nativeMarkers = ["red", "orange", "yellow", "light-green", "dark-green", "light-blue", "dark-blue", "navy-blue", "purple", "pink", "black"]

    if (nativeMarkers.indexOf(location.marker_color.toLowerCase()) == -1) {
        return `https://s3.amazonaws.com/store-locator-markers/${ location.shopid }/${ location.marker_color.toLowerCase() }.png`
    } else {
        return `${ process.env.VUE_APP_URL }/images/2020-icons/${ location.marker_color.toLowerCase() }.png`
    }
}