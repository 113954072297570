<template>


  <div id="w3-storelocator-app" :class="{ 'include-base-css': includeBaseCss, 'desktop-view-active': breakpointApplies }" :style="cssPropsApp">

    <pageTextTop></pageTextTop>

    <template v-if="design.layout == 'default'"><!-- bottom -->
      <div class="map-bottom-layout">
        <SearchBar></SearchBar>
        <LeafletMap></LeafletMap>
        <LocationList></LocationList>
      </div>
    </template>

    <template v-if="design.layout == 'right'">
      <div class="map-right-layout" :style="cssProps">
        <SearchBar></SearchBar>
        <LeafletMap></LeafletMap>
        <LocationList></LocationList>
      </div>
    </template>

    <template v-if="design.layout == 'left'">
      <div class="map-left-layout" :style="cssProps">
        <SearchBar></SearchBar>
        <LocationList></LocationList>
        <LeafletMap></LeafletMap>
      </div>
    </template>

    <pageTextBottom></pageTextBottom>
  </div>
</template>

<script>
/**
 * This file is the root component
 */
import LeafletMap from "./components/LeafletMap";
import SearchBar from "./components/SearchBar";
import LocationList from "./components/LocationList";
import PageTextTop from "./components/PageTextTop";
import PageTextBottom from "./components/PageTextBottom.vue";
import Globals from "./utils/globals";

export default {
  name: "App",
  props: {
    includeBaseCss: {
      default: true,
      type: Boolean
    },
    searchLimit: {
      default: 100,
      type: Number
    },
    distances: {
      default: "5,25,75,150,250,-1"
    },
    defaultDistance: {
      default: 25,
      type: Number
    },
    customStyle: {
      default: "mapbox://styles/jsmithw3/cken4ja761sum1atodz7t64gy"
    }
  },
  data() {
    return {
      design: {
        layout: "bottom"
      },
      breakpointApplies: false
    };
  },
  methods: {},
  created() {
    //console.log(this.distances);

    this.template = Globals.settings.templates.App;
    Globals.searchLimit = this.searchLimit;
    Globals.distances = this.distances.split(",").map(x => {
      return parseInt(x);
    });
    Globals.defaultDistance = this.defaultDistance;

    Globals.customStyle = this.customStyle;

    this.design = Globals.settings.design;

    var breakpoint = parseInt(Globals.settings.design.breakpoint.toString().replace("px", ""));

    if(window.innerWidth >= breakpoint) {
      this.breakpointApplies = true;
    }

  },
  components: {
    LeafletMap,
    LocationList,
    SearchBar,
    PageTextTop,
    PageTextBottom
  },
  computed: {
    cssProps() {
      return {
        '--list-width': this.design.list_width,
        '--break-point': this.design.breakpoint
      }
    },
    cssPropsApp() {
      return {
        '--padding-sides': this.design.padding
      }
    }
  }
};
</script>

<style>
#w3-storelocator-app {
  position: relative;
  padding-left: var(--padding-sides);
  padding-right: var(--padding-sides);
}

.include-base-css.desktop-view-active .map-left-layout  {
  display:grid;

  grid-template-columns: var(--list-width) 1fr;
}

.include-base-css.desktop-view-active .map-right-layout {
    display:grid;

    grid-template-columns: 1fr var(--list-width);
}


.include-base-css.desktop-view-active .search_bar {
  grid-column: 1 / span 2
}

.include-base-css.desktop-view-active .map-left-layout .location-list-container {
  order:0;
}

  

</style>
