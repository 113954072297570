/**
 * This is a custom leaflet control that allows us to inject a Vue component into it.
 */
import { DomUtil, Control, setOptions, DomEvent } from "leaflet";

import eventBus from '../utils/eventBus';

export default Control.extend({
	initialize: function(options) {
		setOptions(this, options);
    },
    
    /*addTo (map) {
        mapp
    }*/

	onAdd: function(map) {
		this._container = DomUtil.create("div");
		this._container.className = "layer-custom-popup leaflet-control-hidden";
		this._container.id = "custom-popup";
		this._container.innerHTML = "";


		eventBus.$on('clickMap', () => {
			this.hide();
		});

		DomEvent.disableClickPropagation(this._container);
		DomEvent.disableScrollPropagation(this._container);
        //this.update();


		return this._container;
	},

	show() {
		this._container.classList.remove("leaflet-control-hidden");
	},

	hide() {
		this._container.classList.add("leaflet-control-hidden");
	},

	setContent(text) {
		this._container.innerHTML = text;
	},

	onRemove: function(map) {
		// Nothing to do here
	},
});
