<template>
  <div class="center w3-loading-indiciator" v-if="visible">
    <div role="progressbar" class="mat-progress-spinner" style="width: 100px; height: 100px;">
      <svg focusable="false" style="width: 100px; height: 100px;">
        <circle color cx="50%" cy="50%" r="45" stroke="black" />
      </svg>
    </div>
  </div>
</template>

<script>
/**
 * A loading bar meant to be reusable and drop in place.
 * Because of UI reasons it ended up only getting used on the location list.
 * The styles were copy pasted from material UI because they looked cool.
 */

import eventBus from '../utils/eventBus';

export default {
  data: function() {
      return {
        visible: true
      }
    },
  created() {

    eventBus.$on("loading", (loading) => {
      this.visible = loading;
    });    

  }
};
</script>

<style scoped>
.center {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 500;
}

.mat-progress-spinner {
  animation: mat-progress-spinner-linear-rotate 2s linear infinite;
  display: block;
  position: relative;
  height: 100px;
  width: 100px;
}

.mat-progress-spinner svg {
  position: absolute;
  transform: rotate(-90deg);
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  transform-origin: center;
  overflow: visible;
}

.mat-progress-spinner svg circle {
  animation-name: mat-progress-spinner-stroke-rotate-100;
  stroke-dasharray: 282.743px;
  stroke-width: 10%;
  transition-property: stroke;
  animation-duration: 4000ms;
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-iteration-count: infinite;
  fill: transparent;
  transform-origin: center;
  transition: stroke-dashoffset 225ms linear;
}

@keyframes mat-progress-spinner-linear-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes mat-progress-spinner-stroke-rotate-100 {
  0% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(0);
  }

  12.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(0);
  }

  12.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(72.5deg);
  }

  25% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(72.5deg);
  }

  25.0001% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(270deg);
  }

  37.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(270deg);
  }

  37.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(161.5deg);
  }

  50% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(161.5deg);
  }

  50.0001% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(180deg);
  }

  62.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(180deg);
  }

  62.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(251.5deg);
  }

  75% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(251.5deg);
  }

  75.0001% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(90deg);
  }

  87.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(90deg);
  }

  87.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(341.5deg);
  }

  100% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
}
</style>