<template v-if="pagetext">
  <div v-html="pagetext" class="bottom_text"></div>
</template>

<script>
import * as apiCalls from "../utils/apiCalls";
import eventBus from "../utils/eventBus";
import Globals from "../utils/globals";

export default {
  name: "PageTextBottom",
  data: function() {
    return {
      loading: false,
    };
  },
  methods: {

  },
  created() {
 
    //console.log(Globals.settings.language);
    this.pagetext = Globals.settings.BelowPageText;

  },
  mounted() {
    
  }
};
</script>


<style>


</style>