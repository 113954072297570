<template>
    <div class="location-item">

        <div class="location-list-item-marker">
            <a href="#map" @click="showMarker(location)" title="Pan map to location">
                <img :src="markerUrl" alt="Map marker icon" />
            </a>
        </div>

        <div class="location-list-item-details">
            <div class="location-list-item-name"><a href="#map" @click="showMarker(location)" title="Pan map to location">{{ location.name }}</a></div>
            <div class="location-list-item-address">
                <a href="#map" @click="showMarker(location)" title="Pan map to location">
                    <span class="address1">{{ location.address }}</span><br v-if="location.address2" />
                    <span class="address2" v-if="location.address2">{{ location.address2 }}</span>
                </a>
            </div>
            <div class="location-list-item-city-line">
                <span>{{ location.city }},</span> <span>{{ location.state }}</span> <span>{{ location.zip }}</span>
            </div>

            <div class="location-list-item-phone" v-if="location.phone">
                <a :href="'tel:' + location.phone">P: {{ location.phone }}</a>
            </div>

            <div class="location-list-item-distance" v-if="location.distance">
                {{ roundedDistance }}
            </div>

            <div class="location-list-item-directions">
                <a target='_blank' class='directions-link' :title="language.directions + ': ' + getAddress(location)" 
                    :href="'https://www.google.com/maps/dir/?api=1&destination=' + getAddress(location)">{{ language.directions }}</a>
            </div>

        </div>
        

        
    </div>
</template>


<script>
/**
 * Represents the div box in the location list
 */
import eventBus from "../utils/eventBus";
import Globals from "../utils/globals";
import * as mapHelpers from '../utils/mapHelpers';


export default {
    name: 'LocationItem',
    props: {
        location: Object
    },
    data: function() {
        return {
            distanceWord: "miles",
            language: {
                directions: "Directions"
            }
        }
    },
    created() {
        this.language = Globals.settings.language;

        if(Globals.settings.useMetric)
            this.distanceWord = "kilometers";
    },
    methods: {
        showMarker(location) {
            eventBus.$emit("showMarkers", [location.marker]);
        },
        getAddress(location) {
            var add_string = location.address + ", " + location.city + ", " + location.state + ", " + location.zip;
            var add_string_formated = encodeURIComponent(add_string);
            return add_string_formated;
        }
    },
    computed: {
        roundedDistance: function(){
            return parseFloat(this.location.distance).toFixed(1) + ' ' + this.distanceWord;
        },
        markerUrl: function() {
            return mapHelpers.getIconUrl(this.location);
        }
    }
}
</script>