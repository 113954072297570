<template>
  <div class="location-list-container">
    <v-runtime-template v-if="template" :template="template"></v-runtime-template>
    <div class="location-list" v-if="!template" :style="cssProps">
      <div class="location-items">
        <template v-for="location in visibleLocations">
          <LocationItem :location="location" :key="location.locationid"></LocationItem>
        </template>
      </div>
      <button
        type="button"
        @click="showMore"
        v-if="locations.length != 0 && visibleLocations.length < locations.length && showMoreButton"
      >Show More</button>


      <div class="no-stores-found" v-if="noLocationsFound">
        {{ language.no_stores_found }}
      </div>
    </div>

    
    <LoadingBar></LoadingBar>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";

import Globals from "../utils/globals";
import eventBus from "../utils/eventBus";

import LocationItem from "./LocationItem";
import LoadingBar from './LoadingBar';


export default {
  name: "LocationList",
  props: {
  },
  data: function() {
    return {
      locations: [],
      template: ``,
      visibleCount: 16,
      incrementShow: 4,
      showMoreButton: true,
      noLocationsFound: false,
      loading: false,
      language: {
        no_stores_found: "No stores found."
      }
    };
  },
  methods: {
    showMore() {
      this.visibleCount = this.visibleCount + this.incrementShow;
    }
  },
  created() {
    eventBus.$on("loading", (loading) => {
      this.loading = loading;
    });    

    eventBus.$on("onLocationsChanged", locations => {
      this.locations = locations;

      if(this.locations.length == 0 && !this.loading) {
        this.noLocationsFound = true;
      } else {
        this.noLocationsFound = false;
      }
    });

    this.template = Globals.settings.templates.LocationList;
    this.language = Globals.settings.language;
    this.design = Globals.settings.design;
  },
  components: {
    LocationItem,
    VRuntimeTemplate,
    LoadingBar
  },
  computed: {
    visibleLocations() {
      if(this.showMoreButton) {
        return this.visibleCount
            ? this.locations.slice(0, this.visibleCount)
            : this.locations;
      } else {
        return this.locations;
      }
      
    },
    cssProps() {
      return {
        '--map-height': this.design.map_height,
      }
    }
  }
};
</script>

<style scoped>
.location-list-container {
  position: relative;
}

.include-base-css .map-left-layout .location-list, .map-right-layout .location-list {
    margin-right:1em;
    max-height:var(--map-height);
    overflow-y:scroll;
  }

  .map-right-layout .location-list {
    margin-left:1em;
    margin-right:0em;
    max-height:var(--map-height);
    overflow-y:scroll;
  }


</style>