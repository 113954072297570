import { render, staticRenderFns } from "./PageTextBottom.vue?vue&type=template&id=32b69af4&v-if=pagetext&"
import script from "./PageTextBottom.vue?vue&type=script&lang=js&"
export * from "./PageTextBottom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports