<template>
    <div class="leaflet-custom-popup-location-list">

        <div class="leaflet-custom-popup-location-item" v-for="location of locations" :key="location.id">

            <div class="location-name">{{ location.name }}</div>
            
            <div class="location-address">{{ location.address }}</div>
            <div class="location-address2" v-if="location.address2">{{ location.address2 }}</div>
            <div class="location-city-line"><span>{{ location.city }},</span> <span>{{ location.state }}</span> <span>{{ location.zip }}</span></div>
            
            <div class="location-phone" v-if="location.phone">
                P: {{ location.phone }}
            </div>

            <div class="location-fax" v-if="location.fax">
                F: {{ location.phone }}
            </div>

            <div class="location-email" v-if="location.email">
                {{ location.email }}
            </div>

            <div class="location-website" v-if="location.website">
                <a :href="formatWebsiteLink(location.website)" target="_blank">
                    {{ location.website_link_text ? location.website_link_text : location.website }}
                </a>
            </div>

            <div v-if="location.info" class="location-notes" v-html="location.info">
            </div>


            <a target='_blank' class='directions-link' :title="language.directions + ': ' + getAddress(location)" 
                    :href="'https://www.google.com/maps/dir/?api=1&destination=' + getAddress(location)">{{ language.directions }}</a>


            <div class="social-media-icons" v-if="location.facebook_link || location.twitter_link || location.instagram_link">
                <span class='facebook' style='margin-right:5px;' v-if="location.facebook_link"><a :href="formatWebsiteLink(location.facebook_link)" target='_blank'><img src='@/assets/facebook.png' alt="Facebook Link" /><span aria-label="Facebook"></span></a></span>
                <span class='twitter' style='margin-right:5px;' v-if="location.twitter_link" ><a :href="formatWebsiteLink(location.twitter_link)" target='_blank'><img src='@/assets/twitter.png' alt="Twitter Link" /></a><span aria-label="Twitter"></span></span>
                <span class='instagram' v-if="location.instagram_link"  ><a :href="formatWebsiteLink(location.instagram_link)" target='_blank'><img src='@/assets/instagram.png' alt="Instagram Link" /></a><span aria-label="Instagram"></span></span>
            </div>

            <div class="location-filters" v-if="location.filters">
                <ul v-if="location.filters.length > 0">
                    <li v-for="filter of location.filters" :key="filter.filterid">
                        {{ filter.name }}
                    </li>
                </ul>
            </div>

            <!--
                    
                    var filters = data.location[0].filters;

                    if (filters.length > 0) {
                        info = info + "<ul style='list-style:disc outside; margin-bottom:0px;'>";
                        jQuery213.each(filters, function (index, value) {
                            info = info + "<li>" + value + "</li>";
                        });
                        info = info + "</ul>";
                    }


-->
                    
            
        </div>
        
    </div>
</template>


<script>
import * as apiCalls from '../utils/apiCalls';
import Globals from "../utils/globals";

///This popup can actually show multiple locations
export default {
    name: 'MarkerPopup',
    data: function() {
        return {
            language: {
                directions: "Directions"
            }
        }
    },
    props: {
        locations: Array
    },
    created() {
        this.locations.forEach((location) => {
            var shop = this.$Shopify.shop;

            apiCalls.getFiltersByLocation(shop, location.locationID).then(({data}) => {
                location.filters = data;

                this.$forceUpdate();
            });
        });

        this.language = Globals.settings.language;
        
    },
    methods: {
        formatWebsiteLink(input) {
       
            input = input.toLowerCase();

            if (!input.startsWith("http://") && !input.startsWith("https://")) {
                return "http://" + input;
            } else {
                return input;
            }
           
        },
        getAddress(location) {
            var add_string = location.address + ", " + location.city + ", " + location.state + ", " + location.zip;
            var add_string_formated = encodeURIComponent(add_string);
            return add_string_formated;
        }
    }
}
</script>