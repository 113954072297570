/* eslint-disable no-unused-vars */

import Vue from 'vue'
import vueCustomElement from 'vue-custom-element';
import axios from 'axios'
import '../node_modules/leaflet/dist/leaflet.css'

import './assets/base.css';

import App from './App.vue'

import * as apiCalls from './utils/apiCalls';

import Globals from './utils/globals';

import 'document-register-element' // ES2015

Vue.prototype.$Shopify = window.Shopify;

//this is the key line that allows Vue to act as sort of a standalone widget. It also handles VueJS no conflict.
Vue.use(vueCustomElement);

Vue.config.productionTip = false

//LOAD the map. First we load the settings.
apiCalls.getSettings(Vue.prototype.$Shopify.shop).then((result) => {

  Globals.settings = result.data;

  var script = document.createElement("script");
  script.innerHTML = Globals.settings.customJs;
  document.body.appendChild(script);

  var style = document.createElement("style");
  style.innerHTML = Globals.settings.customCss;
  document.body.appendChild(style);

  //instantiate the main component, bound to w3-store-locator HTML tag.
  Vue.customElement('w3-store-locator', App, {});
});
